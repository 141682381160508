<template>
  <h3 class="offset-md-1 show-font">Заполните следующие поля:</h3>

  <div class="offset-md-1 container col col-md-6 show-font">
    <form action="" enctype="multipart/form-data">
      <div class="form-group">
        <div class="mt3">
          <label for="username" class="form-label">Имя пользователя:<span class="red-text">*</span></label>
          <select class="form-select" id="username" @change="onChange($event)" v-model="user_id">
            <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
          </select>
        </div>

        <div class="mt-3">
          <label for="short_desc" class="form-label">Краткое описание:<span class="red-text">*</span></label>
          <input type="text" class="form-control" id="short_desc" v-model="short_desc" />
        </div>

        <div class="mt-3">
          <label for="full_desc" class="form-label">Полное описание:<span class="red-text">*</span></label>
          <textarea class="form-control" id="full_desc" rows="5" v-model="full_content" maxlength="2999">
            
          </textarea>
          <p class="float-end fst-italic" :style="full_content && full_content.length == 2999 ? 'color: red':''"> {{ full_content ? full_content.length : 0 }} / 2999 </p>
        </div>

        <div class="mt-3" v-if="current_user?.contract?.contract_type !== 'SIMPLE'">
          <label for="vendor" class="form-label">Производитель:<span class="red-text">*</span></label>
          <select class="form-select" id="vendor" @change="ChangeCat($event)" v-model="vendor_id">
            <option v-for="vendor in vendors" :key="vendor.id" :value="vendor.id">
              {{ vendor.name }}
            </option>
          </select>
        </div>

        <div class="mt-3">
          <div class="row">
            <div class="col">Категория:<span class="red-text">*</span></div>
          </div>
          <select class="form-select mt-2" v-model="category_id">
            <option v-for="cat in categories" :key="cat.id" :value="cat.id">
              {{ cat.name }}
            </option>
          </select>
        </div>

        <div class="mt-3">
          <div class="row">
            <div class="col">Приоритет:<span class="red-text">*</span></div>
            <div class="col text-end">
              <Popper placement="right">
                <button class="btn btn-link" @click.prevent>Как выбрать приоритет</button>
                <template #content>
                  <ul>
                    <li class="text-start" v-for="prio in priorities" :key="prio.priority_id">
                      <strong>{{ prio.priority_name }}:</strong> {{ prio.priority_desc }}
                    </li>
                  </ul>
                </template>
              </Popper>
            </div>
          </div>
          <select class="form-select mt-1" id="priority" v-model="priority_id">
            <option
              v-for="prio in priorities"
              :key="prio.priority_id"
              :value="prio.priority_id"
              :selected="prio.priority_id === priorities.length - 1"
            >
              {{ prio.priority_name }}
            </option>
          </select>
        </div>

        <div class="mt-3">
          <label for="contractor_name" class="form-label">ФИО ответственного (оставьте поле пустым, если это будете вы):</label>
          <input type="text" class="form-control" id="contractor_name" v-model="contractor_name" />
        </div>

        <div class="mt-3">
          <div>Контактный телефон:</div>
          <input class="form-control mt-2" v-maska data-maska="+N ( n ) N доп. N" data-maska-tokens="N:[-0-9]:multiple|n:[0-9]:multiple" value="7" placeholder="+7 (___) ___-__-__ доп. _____" v-model="add_info" />
        </div>
        <div class="mt-3">
          <label for="contractor_email" class="form-label"
            >Электронная почта ответственного (оставьте поле пустым, если это будете вы):<span class="red-text">**</span></label
          >
          <input type="text" class="form-control" id="contractor_email" v-model="contractor_email" />
        </div>

        <div class="mt-3">
          <label for="watchers_emails" class="form-label">Электронная почта наблюдателей (можно ввести несколько через "<b>;</b>"):</label>
          <input type="text" class="form-control" id="watchers_emails" v-model="watchers_emails" />
        </div>

        <div class="input-group mt-4">
          <span class="input-group-text show-font"><i class="bi bi-folder2-open"></i></span>
          <label class="form-control">
            {{ file ? file.name : "Выберите файл архива" }}
            <input
              @change="onFile($event)"
              type="file"
              class="invisible"
              accept=".7z, .ace, .arj, .bin,
      .bin, .cab, .cab, .cbr, .deb, .gz, .gzip, .one, .pak, .pkg, .ppt, .rar, .rpm, .sib, .sis, .sisx, .sit, .sitx, .spl, .spl, .tar,
      .tar-gz, .tgz, .xar, .zip, .zip, .zipx"
          /></label>
        </div>

        <div class="alert alert-primary mt-4">
          <div><span class="red-text">*</span> - Поля обязательные для заполнения</div>
          <div><span class="red-text">**</span> - Заполните, если поле "ФИО ответственного" заполнено</div>
        </div>

        <div class="d-grid gap-2">
          <button type="button" class="btn btn-success mt-4 d-grid gap-2" @click.prevent="submit" :disabled="loading">
          <div v-if="!loading">
            Создать обращение
          </div>
            <div class="d-flex justify-content-center" v-if="loading">
              <div class="spinner-border">
            </div>
          </div>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { vMaska } from "maska"
import Popper from "vue3-popper"
import axios from 'axios'

export default {
  name: "CreateTiketView",
  directives: { maska: vMaska },
  components: { Popper },
  data: () => {
    return {
      categories: null,
      priorities: null,
      users: null,
      prio_desc: null,
      user: null,
      vendors: null,
      phone: null,
      file: null,
      user_id: null,
      short_desc: null,
      full_content: null,
      vendor_id: null,
      category_id: null,
      priority_id: null,
      add_info: null,
      ticket: null,
      contractor_name: null,
      contractor_email: null,
      watchers_emails: null,
      current_user: null,
      loading: false
    }
  },

  created() {
    // let token = localStorage.getItem("token")
    // const fetch_property = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
    // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/users/get-current-user`, fetch_property)
    //   .then((data) => data.json())
    //   .then((data) => (this.current_user = data))
    //   .then(() => {
    //     fetch(
    //       `${process.env.VUE_APP_BASE_API}/api/v1/tickets/category_groups?id=${this.current_user.id}`,
    //       fetch_property
    //     )
    //       .then((data) => data.json())
    //       .then((data) => (this.vendors = data))
    //       .then(() => {
    //         if (this.vendors.length > 0) {
    //           fetch(
    //             `${process.env.VUE_APP_BASE_API}/api/v1/tickets/category?vendor=${this.vendors[0].id}`,
    //             fetch_property
    //           )
    //             .then((data) => data.json())
    //             .then((data) => (this.categories = data))
    //         }
    //       })
    //   })

    axios.get("/users/get-current-user")
    .then((resp)=>{
      this.current_user = resp.data
    })
    .then(()=>{
      axios.get(`/tickets/category_groups?id=${this.current_user.id}`)
      .then( resp2 => {
        this.vendors = resp2.data
      })
      .then(()=>{
        if (this.vendors.length > 0) {
          axios.get(`/tickets/category?vendor=${this.vendors[0].id}`)
          .then(resp3 => {
            this.categories = resp3.data
          })
        }
      })
    })

    // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/tickets/priority`, fetch_property)
    //   .then((data) => data.json())
    //   .then((data) => (this.priorities = data))
    //   .then((data) => {
    //     this.prio_desc = data
    //       .map((prio) => {
    //         return `<strong>${prio.priority_name}</strong>: ${prio.priority_desc}`
    //       })
    //       .join("<br/>")
    //   })

    axios.get('/tickets/priority')
    .then(resp => {
      this.priorities = resp.data
      return resp
    })
    .then(resp => {
      this.prio_desc = resp.data
          .map((prio) => {
            return `<strong>${prio.priority_name}</strong>: ${prio.priority_desc}`
          })
          .join("<br/>")
    })

    // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/users/get-users`, fetch_property)
    //   .then((data) => data.json())
    //   .then((data) => (this.users = data.sort((a, b)=>{if(a.name>b.name){return 1}else if(a.name<b.name){return -1}else{return 0}})))

    axios.get('/users/get-users')
    .then(resp => {
      this.users = resp.data.sort((a, b)=>{if(a.name>b.name){return 1}else if(a.name<b.name){return -1}else{return 0}})
    })
  },
    
  methods: {
    submit() {
      // let token = localStorage.getItem("token")
      this.loading = true
      const body = {
        short_content: this.short_desc,
        full_content: this.full_content,
        initiator_id: this.user_id,
        category_id: this.category_id,
        priority_id: this.priority_id,
        initiator_additional_contacts: this.add_info,
        name: this.contractor_name ? this.contractor_name : null,
        phone: this.add_info,
        email: this.contractor_email ? this.contractor_email : null,
        watchers: this.watchers_emails,
        ticket_id: null,
      }
      // const fetch_property = {
      //   method: "POST",
      //   body: JSON.stringify(body),
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // }
      // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/tickets/create`, fetch_property)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     this.ticket = data
      //   })
      //   .then(() => {
      //     if (this.file) {
      //       var formData = new FormData()
      //       formData.append("file", this.file, this.file.name)
      //       const fetch_property_ = {
      //         method: "POST",
      //         body: formData,
      //         headers: {
      //           Authorization: `Bearer ${token}`,
      //         },
      //       }
      //       fetch(
      //         `${process.env.VUE_APP_BASE_API}/api/v1/tickets/upload?ticket_id=${this.ticket.id}&author_id=${this.ticket.initiator_id}`,
      //         fetch_property_
      //       )
      //         .then((response) => response.json())
      //         .then(() => {
      //           this.$router.push(`ticket/${this.ticket.id}`)
      //         })
      //     } else {
      //       this.$router.push(`ticket/${this.ticket.id}`)
      //     }
      //   })

        axios.post(`/tickets/create`,{},JSON.stringify(body))
        .then(resp => {
          this.ticket = resp.data
        })
        .then(() => {
          if (this.file) {
            var formData = new FormData()
            formData.append("file", this.file, this.file.name)
            axios.post(`/tickets/upload?ticket_id=${this.ticket.id}&author_id=${this.ticket.initiator_id}`, formData ,{})
            .then(() => {
              this.$router.push(`ticket/${this.ticket.id}`)
            })
          }
          else {
            this.$router.push(`ticket/${this.ticket.id}`)
          }
        })

    },
    onChange(event) {
      // let token = localStorage.getItem("token")
      // const fetch_property = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // }
      // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/tickets/category_groups?id=${this.user_id}`, fetch_property)
      //   .then((data) => data.json())
      //   .then((data) => (this.vendors = data))
      //   .then(() => {
      //     this.categories = null
      //     if (this.vendors.length > 0) {
      //       fetch(
      //         `${process.env.VUE_APP_BASE_API}/api/v1/tickets/category?vendor=${this.vendors[0].id}`,
      //         fetch_property
      //       )
      //         .then((data) => data.json())
      //         .then((data) => (this.categories = data))
      //     }
      //   })
        axios.get(`/tickets/category_groups?id=${this.user_id}`)
        .then(resp => {
          this.vendors = resp.data
        })
        .then(() => {
          this.categories = null
          if (this.vendors.length > 0) {
            axios.get(`/tickets/category?vendor=${this.vendors[0].id}`)
            .then(resp2 => {
              this.categories = resp2.data
            })
          }
        })

      // fetch(
      //   `${process.env.VUE_APP_BASE_API}/api/v1/tickets/priority?user_id=${event.target.value}`,
      //   fetch_property
      // )
      //   .then((data) => data.json())
      //   .then((data) => (this.priorities = data))
      //   .then((data) => {
      //     this.prio_desc = data
      //       .map((prio) => {
      //         return `<strong>${prio.priority_name}</strong>: ${prio.priority_desc}`
      //       })
      //       .join("<br/>")
      //   })

        axios.get(`/tickets/priority?user_id=${event.target.value}`)
        .then(resp => {
          this.priorities = resp.data
          return resp
        })
        .then(resp => {
          this.prio_desc = resp.data
            .map((prio) => {
              return `<strong>${prio.priority_name}</strong>: ${prio.priority_desc}`
            })
            .join("<br/>")
        })
    },
    ChangeCat(event) {
      // let token = localStorage.getItem("token")
      // const fetch_property = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // }

      // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/tickets/category?vendor=${event.target.value}`, fetch_property)
      //   .then((data) => data.json())
      //   .then((data) => (this.categories = data))
      axios.get(`/tickets/category?vendor=${event.target.value}`)
      .this(resp => {
        this.categories = resp.data
      })
    },
    onFile(event) {
      this.file = event.target.files[0]
    },
  },
}
</script>

<style>
:root {
  --popper-theme-background-color: #eeeeee;
  --popper-theme-background-color-hover: #eeeeee;
  --popper-theme-text-color: #415a78;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 15px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  --popper-width: 100px;
}
.popper {
  width: 350px;
}
.red-text {
  color: rgb(184, 33, 33);
}
</style>
