<template>
  <div class="offset-md-1 col-md-8 show-font">
    <h3 class="mb-3">Баланс по текущим контрактам на начало {{ formatDate(date) }}</h3>
  </div>

  <div class="offset-md-1 col-md-7 show-font">
    <a :href="balanceURL" @click.prevent="downloadItem">Скачать отчёт</a>&nbsp;&nbsp;
    <div class="spinner-border spinner-border-sm text-primary" role="status" v-if="download_started">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div class="container-fluid mt-4 show-font" v-if="balances">
    <table :class="classTableView">
      <thead>
        <tr scope="row" class="d-flex">
          <th class="col-2 pointer" click="sortby('customer')"><span class="wback">Заказчик</span></th>
          <th class="col-2 pointer" click="sortby('contract_id')"><span class="wback">Контракт</span></th>
          <th class="col-1 pointer" click="sortby('balance_h')"><span class="wback">Пакет ч.</span></th>
          <th class="col-1 pointer"><span class="wback">Списано ч.</span></th>
          <th class="col-1 pointer" click="sortby('frozen_hours')"><span class="wback">Заморожено ч.</span></th>
          <th class="col-1 pointer" click="sortby('left_hours')"><span class="wback">Остаток ч.</span></th>
          <th class="col-2 pointer" click="sortby('valid_from')"><span class="wback">Дата начала</span></th>
          <th class="col-2 pointer" click="sortby('valid_by')"><span class="wback">Дата окончания</span></th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" class="d-flex" v-for="balance in balances" :key="balance.id">
          <td class="col-2 text-start">
            <i v-bind:class="classContract(balance)"></i> <span class="wback">{{ balance.customer }}</span>
          </td>
          <td class="col-2">
            <span class="wback">{{ balance.contract_id }}</span>
          </td>
          <td class="col-1">
            <span class="wback">{{ balance.balance_h }} ч.</span>
          </td>
          <td class="col-1">
            <span class="wback">{{ (balance.balance_h - balance.left_hours - balance.frozen_hours).toFixed(2) }} ч.</span>
          </td>
          <td class="col-1">
            <span class="wback">{{ balance.frozen_hours }} ч.</span>
          </td>
          <td class="col-1">
            <span class="wback">{{ balance.left_hours }}</span>
          </td>
          <td class="col-2">
            <span class="wback">{{ formatDate(balance.valid_from) }}</span>
          </td>
          <td class="col-2">
            <span class="wback">{{ formatDate(balance.valid_by) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center col col-sm-9">
    <div class="spinner-border text-primary mt-5 mb-5" role="status" v-if="!balances">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="wback show-font col-sm-10">
    Замороженные часы - часы, потраченные на обращения, находящиеся в работе. После завершения таких обращений, эти часы перейдут в разряд
    списанных.
  </div>
</template>

<script>
import { saveAs } from "file-saver"
import { globals } from '@/main.js'
import axios from 'axios'

export default {
  name: "BalancesView",
  data: () => {
    return {
      date: new Date(),
      current_user: null,
      balances: null,
      customer: "customer",
      contract_id: "contract_id",
      left_hours: "left_hours",
      balance_h: "balance_h",
      valid_from: "valid_from",
      valid_by: "valid_by",
      frozen_hours: "frozen_hours",
      balanceURL: `${globals.$api}/api/v1/reports/balances-file`,
      download_started: false,
    }
  },
  methods: {
    formatDate(inp_) {
      if (typeof inp_ === "string") {
        const date = new Date(inp_)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day >= 10 ? day : "0" + day}.${month >= 10 ? month : "0" + month}.${year}`
      } else {
        const day = inp_.getDate()
        const month = inp_.getMonth() + 1
        const year = inp_.getFullYear()
        return `${day >= 10 ? day : "0" + day}.${month >= 10 ? month : "0" + month}.${year}`
      }
    },

    showInputs() {
      this.generateReport()
    },

    dynamicSort(property) {
      let sortOrder = 1
      if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
      }
      return function (a, b) {
        let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
      }
    },

    sortby(param) {
      this.balances.sort(this.dynamicSort(this[param]))
      if (this[param][0] === "-") {
        this[param] = this[param].slice(1)
      } else {
        this[param] = "-" + this[param]
      }
    },

    generateReport() {
      // let token = localStorage.getItem("token")
      // const fetch_property = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // }
      // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/reports/balances`, fetch_property)
      //   .then((data) => data.json())
      //   .then((data) => (this.balances = data))
      axios.get('/reports/balances')
      .then(resp=>{
        this.balances = resp.data
      })
    },

    checkContract(customer) {
      return customer.left_hours > 0 && new Date(customer.valid_by).getTime() > new Date().getTime()
    },

    classContract(customer) {
      return {
        "bi bi-x-circle-fill": this.checkContract(customer) ? false : true,
        "bi bi-check-circle-fill": this.checkContract(customer) ? true : false,
        "text-danger": this.checkContract(customer) ? false : true,
        "text-success": this.checkContract(customer) ? true : false,
      }
    },

    downloadItem() {
      this.download_started = true
      // let token = localStorage.getItem("token")
      // const fetch_property = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "text/csv",
      //     Authorization: `Bearer ${token}`,
      //     responseType: "blob",
      //   },
      // }
      // fetch(`${process.env.VUE_APP_BASE_API}/api/v1/reports/balances-file`, fetch_property)
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     const file = new Blob([blob], { type: "application/text" })
      //     saveAs(file, `balances-${this.formatDate(this.date)}.txt`)
      //   })
      //   .then(() => (this.download_started = false))
      axios.get('/reports/balances-file', {responseType: 'blob'})
      .then(resp => {
        const file = new Blob([resp.data], { type: "application/text"})
        saveAs(file, `balances-${this.formatDate(this.date)}.txt`)
      })
      .then(()=>{
        this.download_started = false
      })
    },

  },
  created() {
    this.generateReport()
  },
  computed: {
    // download_url() {
    //   return `${process.env.VUE_APP_BASE_API}/api/v1/reports/balances-file`
    // },
    classTableView() {
      return {
        table: true,
        "table-hover": true,
        "text-center": true,
        col: true,
        "col-sm-10": true,
        "show-font": true,
        "table-sm": true,
      }
    },
  },
}
</script>

<style>
.table {
  --bs-table-bg: transparent !important;
}
</style>
