<template>
  <div class="offset-md-1 container mb-3 show-font">
    <div class="row">
      <div class="col col-sm-5" v-if="current_user">
        <h3>Контракт: {{ current_user.contract.customer }}</h3>
        <div :class="classContractHours" role="alert" v-if="current_user.contract.contract_type !== 'SIMPLE'">
          Доступных часов поддержки: {{ current_user.contract_hours }}
        </div>
      </div>
    </div>
  </div>
  <div class="offset-md-1 col col-sm-5">
    <hr />
  </div>
  <div class="offset-md-1 container mb-4 show-font">
    <div class="row">
      <div class="col col-sm-5">
        <h3>Создать обращение</h3>
        <a
          class="btn btn-primary d-grid gap-2 mt-2"
          href="/create-ticket"
          role="button"
          @click.prevent="goTo('/create-ticket', false)"
          @click.middle="goTo('/create-ticket', true)"
          >Создать новое обращение</a
        >
        <a
          v-if="current_user && current_user.is_moderator"
          class="btn btn-primary d-grid gap-2 mt-2"
          href="/new-contract"
          role="button"
          @click.prevent="goTo('/new-contract', false)"
          @click.middle="goTo('/new-contract', true)"
          >Создать новый контракт</a
        >
        <a
          v-if="false"
          class="btn btn-primary d-grid gap-2 mt-2"
          href="/service-catalog"
          role="button"
          @click.prevent="goTo('/service-catalog', false)"
          @click.middle="goTo('/service-catalog', true)"
          >Выбрать из каталога услуг</a
        >
      </div>
    </div>
  </div>
  <div class="offset-md-1 col col-sm-5">
    <hr />
  </div>
  <div class="offset-md-1 container mt-4 show-font">
    <div class="row">
      <div class="col col-sm-5">
        <h3>Информация о существующем обращении</h3>

        <div class="input-group mb-3 mt-3">
          <input
            type="text"
            class="form-control"
            placeholder="Введите номер обращения"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            v-model="ticket_number"
          />
          <button
            class="btn btn-primary"
            type="button"
            id="button-addon2"
            @click.prevent="showTicket(ticket_number, false)"
            @click.middle="showTicket(ticket_number, true)"
          >
            Показать
          </button>
        </div>
        <div class="alert alert-danger d-flex align-items-center mt-3" role="alert" v-if="visible_ticket_alert">
          <div>Введите коректный номер обращения</div>
        </div>
      </div>
    </div>
  </div>
  <div class="offset-md-1 col col-sm-5">
    <hr />
  </div>
  <div
    class="offset-md-1 container mb-4 show-font"
    v-if="current_user && current_user.is_moderator && current_user.contract.contract_type !== 'SIMPLE'"
  >
    <div class="row">
      <div class="col col-sm-5">
        <h3>Отчёты супервайзера</h3>
        <a
          class="btn btn-primary d-grid gap-2 mt-4"
          href="/reports"
          role="button"
          @click.prevent="goTo('/reports', false)"
          @click.middle="goTo('/reports', true)"
          >Расход часов по обращениям</a
        >
        <a
          class="btn btn-primary d-grid gap-2 mt-4"
          href="/balances"
          role="button"
          @click.prevent="goTo('/balances', false)"
          @click.middle="goTo('/balances', true)"
          >Баланс по текущим контрактам</a
        >
        <a
          class="btn btn-primary d-grid gap-2 mt-4"
          href="/customer"
          role="button"
          @click.prevent="goTo('/customer', false)"
          @click.middle="goTo('/customer', true)"
          >Обращения по заказчику</a
        >
      </div>
    </div>
  </div>
  <div
    class="offset-md-1 col col-sm-5"
    v-if="current_user && current_user.is_moderator && current_user.contract.contract_type !== 'SIMPLE'"
  >
    <hr />
  </div>
  <div class="offset-md-1 col col-sm-10 show-font">
    <h3 class="mb-4">Текущие обращения</h3>
    <div class="form-check form-check-inline" v-if="tickets">
      <input type="checkbox" class="form-check-input" id="includeClosed" @click="includeClosed" />
      <label class="form-check-label" for="includeClosed">Включая закрытые</label>
    </div>
    <div>
      <hr />
    </div>
    <table :class="classTableView" v-if="tickets">
      <thead>
        <tr scope="row" class="d-flex">
          <th class="col-1 pointer" @click="sortby('id')">№ Обращения</th>
          <th class="col-2 pointer" @click="sortby('contract_name')">Заказчик</th>
          <th class="col-4 pointer" @click="sortby('short_content')">Краткое описание</th>
          <th class="col-2 pointer" @click="sortby('priority_id')">Приоритет</th>
          <th class="col-1 pointer" @click="sortby('status_id')">Статус</th>
          <th class="col-2 pointer" @click="sortby('time_cost')">Часов потрачено</th>
        </tr>
      </thead>
      <tbody v-for="ticket in tickets" :key="ticket.id">
        <tr scope="row" class="d-flex pointer" @click="openTicket(ticket.id)" @click.middle="openTicket(ticket.id)">
          <th class="col-1">{{ ticket.id }}</th>
          <td class="col-2">{{ ticket.contract_name }}</td>
          <td class="col-4 text-start">{{ ticket.short_content }}</td>
          <td class="col-2">{{ ticket.priority.name }}</td>
          <td class="col-1">{{ ticket.status.name }}</td>
          <td class="col-2">{{ ticket.time_cost }} ч.</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-primary mt-5" role="status" v-if="!tickets">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "HomeView",
  data: () => {
    return {
      current_user: null,
      tickets: null,
      unsortedTickets: null,
      include_closed: false,
      short_content: "short_content",
      priority_id: "priority_id",
      status_id: "status_id",
      time_cost: "time_cost",
      ticket_number: null,
      visible_ticket_alert: false,
      id: "id",
      contract_name: "contract_name",
    }
  },

  created() {
    // let token = localStorage.getItem("token")
    // const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`
    // const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`
    //   }

    axios
      // .get(`${base_url}/users/get-current-user`, {headers})
      .get('/users/get-current-user', {})
      .then(resp => {
        this.current_user = resp.data
      })

    axios
      // .get(`${base_url}/tickets/?exclude_status=Закрыт`, {headers})
      .get('/tickets/?exclude_status=Закрыт', {})
      .then(resp=>{
        this.unsortedTickets = resp.data
        this.tickets = this.unsortedTickets.sort(this.compareR).filter((ticket) => ticket.status.name !== "Закрыт")
      })
      
      axios
        // .get(`${base_url}/tickets/?only_status=Закрыт`, {headers})
        .get('/tickets/?only_status=Закрыт', {})
        .then( resp => {
          this.unsortedTickets = this.unsortedTickets.concat(resp.data)
        })

  },

  methods: {
    compareR(a, b) {
      if (a.status_id > b.status_id) return 1
      if (a.status_id < b.status_id) return -1
      if (a.id > b.id) return 1
      if (a.id < b.id) return -1
      return 0
    },
    dynamicSort(property) {
      let sortOrder = 1
      if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
      }
      return function (a, b) {
        let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
      }
    },
    includeClosed() {
      if (!this.include_closed) {
        this.tickets = this.unsortedTickets.sort(this.compareR)
        this.include_closed = true
      } else {
        this.tickets = this.unsortedTickets.sort(this.compareR).filter((ticket) => ticket.status.name !== "Закрыт")
        this.include_closed = false
      }
    },

    sortby(param) {
      this.tickets.sort(this.dynamicSort(this[param]))
      if (this[param][0] === "-") {
        this[param] = this[param].slice(1)
      } else {
        this[param] = "-" + this[param]
      }
    },

    openTicket(ticketId) {
      const routeData = this.$router.resolve({ name: "ticket", params: { id: ticketId } })
      window.open(routeData.href, "_blank")
    },

    showTicket(ticketId, blank) {
      if (isNaN(ticketId)) {
        this.visible_ticket_alert = true
      } else {
        if (blank) {
          this.openTicket(ticketId)
        } else {
          this.$router.push(`/ticket/${ticketId}`)
        }
      }
    },
    goTo(path, blank) {
      if (blank) {
        const routeData = this.$router.resolve({ name: path.slice(1) })
        window.open(routeData.href, "_blank")
      } else {
        this.$router.push(path)
      }
    },
  },

  computed: {
    classContractHours() {
      if (this.current_user) {
        return {
          alert: true,
          "mt-4": true,
          "alert-danger": this.current_user.contract_hours / this.current_user.contract.balance_h <= 0.1,
          "alert-warning": 0.1 < this.current_user.contract_hours / this.current_user.contract.balance_h <= 0.3,
          "alert-primary": this.current_user.contract_hours / this.current_user.contract.balance_h > 0.3,
        }
      } else {
        return {
          alert: true,
          "mt-4": true,
        }
      }
    },
    classTableView() {
      return {
        table: true,
        "table-hover": true,
        "text-center": true,
        col: true,
        "col-sm-6": true,
        "show-font": true,
        "table-sm": true,
        "mt-2": true,
      }
    },
  },
}
</script>
