<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from "vue";

const props = defineProps({
  id: {
      type: String,
      default: null
  },
  data: {
    type: Object,
    default() {}
  },
});

const emit = defineEmits(["pushData"])
const search = ref("");

watch(props, () => {
    //console.log(`${props.id}::SEARCHWEBPART_WATCH--props`)
    filteredList()
},{deep:true});
watch(search, () => {
    //console.log(`${props.id}::SEARCHWEBPART_WATCH--search`)
    filteredList()
});

onMounted(() => {
  const searchInput = document.getElementById(props.id + "-search");
  const myCollapsible = document.getElementById(props.id + "-dd");
  if (myCollapsible) {
    myCollapsible.addEventListener("hide.bs.dropdown", () => {
      search.value = "";
    });
    myCollapsible.addEventListener("shown.bs.dropdown", () => {
      searchInput.focus();
    });
  }
})

function filteredList() {
  //console.log('SEARCHWEBPART_FUNCTION--filteredList')
  if (props.data && search.value) {
    // //console.log('SEARCHWEBPART_FUNCTION--filteredList-if (props.data && search.value)')
    //console.log('-- props.data.value --')
    //console.log(props.data.value)
    //console.log('-- search.value --')
    //console.log(search.value)
    
    emit('pushData', props.data.value.filter(item => item.text.toLowerCase().includes(search.value.toLowerCase()) ) )
    //console.log('-- filter().value --')
    //console.log(props.data.value.filter(item => item.text.toLowerCase().includes(search.value.toLowerCase()) ))
  }
  else{
    //console.log('SEARCHWEBPART_FUNCTION--filteredList-if NOT (props.data && search.value)')
    //console.log('-- props.data.value --')
    //console.log(props.data.value)
    emit('pushData', props.data.value);
  }
}

</script>

<template>
  <div class="col input-group mx-1 mb-2">
    <input
      placeholder="Поиск"
      v-model="search"
      type="text"
      class="form-control"
      style="background-image:none;border-color: inherit;"
      :id="id+'-search'"
    >
  </div>

</template>
