<template>
  <div class="offset-md-1 col-md-7 show-font">
    <h3 class="mb-4">Расход часов по обращениям</h3>
    <form enctype="multipart/form-data">
      <div class="form-row">
        <div class="show-font input-group" id="calendar">
          <span class="col-sm-1 input-group-text show-font">От:</span>

          <VueDatePicker
            v-model="startdate"
            locale="ru"
            cancelText="Отмена"
            selectText="Выбрать"
            :format="format"
            class="form-control"
          ></VueDatePicker>

          <span class="col-sm-1 input-group-text show-font">До:</span>

          <VueDatePicker
            v-model="enddate"
            locale="ru"
            cancelText="Отмена"
            selectText="Выбрать"
            :format="format"
            class="form-control"
          ></VueDatePicker>
          <button class="btn btn-success" @click.prevent="generateReport">Показать отчёт</button>
        </div>
      </div>
    </form>
  </div>

  <div class="offset-md-1 col-md-7 mt-2 show-font" v-if="startdate && enddate">
    <a :href="ticketsURL" @click.prevent="downloadItem">Скачать отчёт</a>&nbsp;&nbsp;
    <div class="spinner-border spinner-border-sm text-primary" role="status" v-if="download_started">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
  <div class="offset-md-1 col-md-7 mt-2 show-font" v-else>
    <b>Что бы скачать отчет необходимо заполнить обе даты</b>&nbsp;&nbsp;
  </div>

  <div v-if="tickets">
    <div class="offset-md-1 mt-5 show-font" v-for="(customer_tickets, customer) in tickets" :key="customer">
      <h3 class="mb-4">{{ customer }}</h3>
      <div class="col-9">
        <hr />
      </div>
      <table :class="classTableView">
        <thead>
          <tr scope="row" class="d-flex">
            <th class="col-md-1 pointer" @click="sortby('id', customer)">№ Обращения</th>
            <th class="col-md-3 pointer" @click="sortby('short_content', customer)">Краткое описание</th>
            <th class="col-md-2 pointer" @click="sortby('creation_timestamp', customer)">Создан</th>
            <th class="col-md-1 pointer" @click="sortby('status_id', customer)">Статус</th>
            <th class="col-md-2 pointer" @click="sortby('time_cost', customer)">Часов потрачено</th>
          </tr>
        </thead>
        <tbody v-for="ticket in customer_tickets" :key="ticket.id">
          <tr scope="row" class="d-flex pointer" @click="openTicket(ticket.id)" @click.middle="openTicket(ticket.id)">
            <th class="col-md-1">{{ ticket.id }}</th>
            <td class="col-md-3 t">{{ ticket.short_content }}</td>
            <td class="col-md-2">{{ formatDate(ticket.creation_timestamp) }}</td>
            <td class="col-md-1">{{ ticket.status.name }}</td>
            <td class="col-md-2">{{ ticket.time_cost }} ч.</td>
          </tr>
        </tbody>
        <tr scope="row" class="d-flex" style="font-weight: bold">
          <th class="col-md-1"></th>
          <td class="col-md-3 text-start"></td>
          <td class="col-md-2"></td>
          <td class="col-md-1 text-start">Заморожено:</td>
          <td class="col-md-2">{{ calculateFrozen(customer_tickets) }} ч.</td>
        </tr>
        <tr scope="row" class="d-flex" style="font-weight: bold">
          <th class="col-md-1"></th>
          <td class="col-md-3 text-start"></td>
          <td class="col-md-2"></td>
          <td class="col-md-1 text-start">Списано:</td>
          <td class="col-md-2">{{ calculateClosed(customer_tickets) }} ч.</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="d-flex justify-content-center col col-sm-9">
    <div class="spinner-border text-primary mt-5" role="status" v-if="!tickets">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker"
import axios from 'axios'
import "@vuepic/vue-datepicker/dist/main.css"
import { saveAs } from "file-saver"
import { globals } from '@/main.js'

export default {
  name: "ReportsView",
  components: { VueDatePicker },
  data() {
    return {
      startdate: this.getFirstDay(),
      enddate: this.getLastDay(),
      id: "id",
      tickets: null,
      short_content: "short_content",
      status_id: "status_id",
      time_cost: "time_cost",
      creation_timestamp: "creation_timestamp",
      ticketsURL: `${globals.$api}/reports/tickets-file/`,
      download_started: false,
    }
  },
  methods: {
    format(date) {
      if(date){
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        return `${day >= 10 ? day : "0" + day}.${month >= 10 ? month : "0" + month}.${year}`
      }
      return null
    },
    formatDate(inp_) {
      const date = new Date(inp_)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${day >= 10 ? day : "0" + day}.${month >= 10 ? month : "0" + month}.${year} ${hours >= 10 ? hours : "0" + hours}:${
        minutes >= 10 ? minutes : "0" + minutes
      }`
    },
    formatDateNoTime(inp_) {
      const date = new Date(inp_)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day >= 10 ? day : "0" + day}.${month >= 10 ? month : "0" + month}.${year}`
    },

    getFirstDay() {
      let today = new Date()
      return new Date(`${today.getFullYear()}-${today.getMonth() + 1}-01`)
    },

    getLastDay() {
      let today = new Date()
      let last = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      return new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${last.getDate()}`)
    },
    openTicket(ticketId) {
      const routeData = this.$router.resolve({ name: "ticket", params: { id: ticketId } })
      window.open(routeData.href, "_blank")
    },
    sortby(param, customer) {
      this.tickets[customer].sort(this.dynamicSort(this[param]))
      if (this[param][0] === "-") {
        this[param] = this[param].slice(1)
      } else {
        this[param] = "-" + this[param]
      }
    },
    dynamicSort(property) {
      let sortOrder = 1
      if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
      }
      return function (a, b) {
        let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
      }
    },
    compareR(a, b) {
      if (a.status_id > b.status_id) return 1
      if (a.status_id < b.status_id) return -1
      if (a.id > b.id) return 1
      if (a.id < b.id) return -1
      return 0
    },
    generateReport() {

      //TODO Cannot generate report in no Date (Not implemented on Backend)
      this.tickets = null
      let args = ''
      if(this.startdate){
        args.includes('?') ? args += '&' : args += '?'
        args += `start_date=${this.format(this.startdate)}`
      }
      if(this.enddate){
        args.includes('?') ? args += '&' : args += '?'
        args += `end_date=${this.format(this.enddate)}`
      }

      
      axios.get(`/reports/tickets${args}`, {})
        .then((resp) => {
          if (resp.data) {
            let tic = resp.data
            let result = tic.reduce(function (r, a) {
              r[a.customer] = r[a.customer] || []
              r[a.customer].push(a)
              return r
            }, Object.create(null))
            return result
          } else {
            return []
          }
      })
      
        .then((resp) => {
          Object.keys(resp).forEach((key) => resp[key].sort(this.compareR))
          return resp
        })
        .then((resp) => {
          this.tickets = resp
          console.log("resp")
            console.log(resp)
        })
    },
    calculateFrozen(arr) {
      const total = arr.reduce((acc, t) => {
        !t.completed_date ? (acc += t.time_cost) : false
        return acc
      }, 0)
      return total.toFixed(2)
    },
    calculateClosed(arr) {
      const total = arr.reduce((acc, t) => {
        t.completed_date ? (acc += t.time_cost) : false
        return acc
      }, 0)
      return total.toFixed(2)
    },
    downloadItem() {
      this.download_started = true
      let args = ''
      if(this.startdate){
        args.includes('?') ? args += '&' : args += '?'
        args += `start_date=${this.format(this.startdate)}`
      }
      if(this.enddate){
        args.includes('?') ? args += '&' : args += '?'
        args += `end_date=${this.format(this.enddate)}`
      }
      

      // let token = localStorage.getItem("token")
      // const fetch_property = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "text/csv",
      //     Authorization: `Bearer ${token}`,
      //     responseType: "blob",
      //   },
      // }
      // fetch(
      //   `${process.env.VUE_APP_BASE_API}/api/v1/reports/tickets-file?start_date=${this.format(
      //     this.startdate
      //   )}&end_date=${this.format(this.enddate)}`,
      //   fetch_property
      // )
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     const file = new Blob([blob], { type: "application/text" })
      //     saveAs(file, `tickets-${this.formatDateNoTime(this.startdate)}-${this.formatDateNoTime(this.enddate)}.txt`)
      //   })
      //   .then(() => (this.download_started = false))
      axios.get(`/reports/tickets-file${args}`, {responseType: 'blob'})
      .then(resp => {
        const file = new Blob([resp.data], { type: "application/text"})

        let fileName = 'tickets'
        if(this.startdate){
          fileName += `-${this.formatDateNoTime(this.startdate)}`
        }
        else{
          let first = this.formatDateNoTime( resp.data[0].creation_timestamp )
          fileName += `-${first}`
        }
        if(this.enddate){
          fileName += `-${this.formatDateNoTime(this.enddate)}`
        }
        else{
          let last = this.formatDateNoTime( resp.data[resp.data.length -1].creation_timestamp )
          fileName += `-${last}`
        }

        saveAs(file, `tickets${fileName}.txt`)
      })
      .then(()=>{
        this.download_started = false
      })
    },
  },
  computed: {
    classTableView() {
      return {
        table: true,
        "table-hover": true,
        "text-center": true,
        col: true,
        "col-sm-7": true,
        "show-font": true,
        "table-sm": true,
      }
    },
  },
  created() {
    this.generateReport()
  },
}
</script>

<style>
.dp__input {
  border: 0px solid;
}
</style>
