<template>
  <a class="logout btn btn-info" href="/" @click="logout">Выйти</a>
</template>

<script>
export default {
  name: "ButtonLogout",
  methods: {
    logout() {
      this.$store.commit('dropToken')
      // localStorage.removeItem("token")
    },
  },
}
</script>

<style>
.logout {
  position: fixed;
  right: 30px;
  bottom: 20px;
  opacity: 1;
  padding: 10px;
  color: #fff;
  background-color: #1ea4bf;
}
</style>
