import { createRouter, createWebHashHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"
// import LoginView from "../views/LoginView.vue"
import LoginView from "../views/LoginPage.vue"
import BalancesView from "../views/BalancesView.vue"
import CustomerView from "../views/CustomerView.vue"
import TicketView from "../views/TicketView.vue"
import TicketPage from "../views/TicketPage.vue"
import ReportsView from "../views/ReportsView.vue"
import ReportsBalanceCardsView from "../views/ReportsBalanceCardsView.vue"
import CreateTicketView from "../views/CreateTicketView.vue"
import CreateTicketPage from "../views/CreateTicketPage.vue"
import NewContractPage from "../views/NewContractPage.vue"
import ServiceCatalog from "../views/ServiceCatalogPage.vue"
import CatalogItemConstructor from "../views/FormConstructorPage.vue"
import ServiceCatalogCardPage from "../views/ServiceCatalogCardPage.vue"
import axios from 'axios'

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requireAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/balances",
    name: "balances",
    component: BalancesView,
    meta: { requireAuth: true },
  },
  {
    path: "/customer",
    name: "customer",
    component: CustomerView,
    meta: { requireAuth: true },
  },
  {
    path: "/ticket/:id",
    name: "ticket",
    component: TicketView,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/ticket2/:id",
    name: "ticket2",
    component: TicketPage,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsView,
    meta: { requireAuth: true },
  },
  {
    path: "/reports/balance-card",
    name: "ReportsBalanceCardsView",
    component: ReportsBalanceCardsView,
    meta: { requireAuth: true },
  },
  {
    path: "/create-ticket-old",
    name: "create-ticket-old",
    component: CreateTicketView,
    meta: { requireAuth: true },
  },
  {
    path: "/create-ticket",
    name: "create-ticket",
    component: CreateTicketPage,
    meta: { requireAuth: true },
  },
  {
    path: "/new-contract",
    name: "new-contract",
    component: NewContractPage,
    meta: { requireAuth: true },
  },
  {
    path: "/service-catalog",
    name: "service-catalog",
    component: ServiceCatalog,
    meta: { requireAuth: true },
  },
  {
    path: "/service-catalog/item/:name",
    name: "service-catalog-item",
    component: ServiceCatalogCardPage,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/catalog-item-constructor",
    name: "catalog-item-constructor",
    component: CatalogItemConstructor,
    meta: { requireAuth: true },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.auth = false

// const response = await axios.get.get(`/config.json`)

// const getCountries = async (currencyCode) => {
//   try {
//     const response = await axios.get('/config.json');

//     return response.data.VUE_APP_BASE_API + response.data.VUE_APP_API;
//   } catch (error) {
//     throw new Error(`Unable to get config that use ${error}`);
//   }
// };



router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
  console.log(to, from, next)
  const token = localStorage.getItem("token")
 
  if (token) {
    if(axios.defaults.baseURL){
      console.log("axios.defaults.baseURL - in if")
      console.log(axios.defaults.baseURL)
      axios
      .get('/auth/verify-token', {
      })
      .then(resp => {
        if(resp.status === 200){
          next()
        }
        else{
          next({
            path: "/login",
          })
        }
      })
      .catch(e => {
        console.log("error")
        console.log(e)
        next({
          path: "/login",
        })
      })
    }
    else{
      console.log("axios.defaults.baseURL - in else")
      console.log(axios.defaults.baseURL)
      axios
    .get(`/config.json`)
    .then(resp => {
        let config = resp.data
        axios.defaults.baseURL = config.VUE_APP_BASE_API + config.VUE_APP_API
    })
    .then(()=>{
      axios
      .get('/auth/verify-token', {
      })
      .then(resp => {
        if(resp.status === 200){
          next()
        }
        else{
          next({
            path: "/login",
          })
        }
      })
      .catch(e => {
        console.log("error")
        console.log(e)
        next({
          path: "/login",
        })
      })
    })
    .catch(error => {
      console.log(error)
    });
    }
    
  } else {
    next({
      path: "/login",
    })
  }
}
else {
    next()
  }
})

export default router
