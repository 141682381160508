import axios from "axios";

const user = {
    state: () => ({
        user: {},
        // token: localStorage.getItem("token") || ""
        token: ""
    }),
    actions: {
        getUser ({ state })  {
            // const token = localStorage.getItem("token");
            // const base_url = `${process.env.VUE_APP_BASE_API}/api/v1`;
            // const headers = {
            // "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
            // };
            if( Object.keys(state.user).length ){
                return state.user
            }
            else {

            return axios.get('/users/get-current-user').then(resp => {
                    state.user = resp.data
                    return resp.data
                });
            }
        }
    },
    mutations: {
        setToken(state, payload){
            state.token = payload
            localStorage.setItem("token", `Bearer ${payload}`)
            axios.defaults.headers.common['Authorization'] = `Bearer ${payload}`
        },
        dropToken(state){
            state.token = null,
            localStorage.removeItem("token")
        }
    },
    getters: {
        getToken(state){
            return state.token
        }
    }
}
export default user