<template>
  <div class="container">
    <nav class="navbar navbar-light bg-light">
      <div class="ml-1">
        <a class="navbar-brand" href="/">
          <img src="@/assets/images/softline.png" alt style="padding: 5px">
        </a>
      </div>
    </nav>
    <div class="container mt-4 mb-4">
      <router-view/>
      <ButtonLogout/>
      <ButtonMain/>
    </div>
    <div class="navbar fixed-bottom">
      <p>
        <small>Версия веб-интерфейса {{appVersion.version}}</small>
      </p>
    </div>
    <Toast/>
  </div>
</template>

<script>
import ButtonLogout from "./components/ButtonLogout.vue";
import ButtonMain from "./components/ButtonMain.vue";
import Toast from "./components/ShowToast.vue";
import version from "./../package.json";
import axios from 'axios'

import { globals } from '@/main.js'

export default {
  components: {
    ButtonLogout,
    ButtonMain,
    Toast
  },
  data: () => ({
    appVersion: version
  }),
  beforeCreate() {
  this.$nextTick(function () {
    if(globals.$config){
      document.title = globals.$config.VUE_APP_TITILE
    }
    else{
      axios.get('/config.json').then((resp)=>{
        document.title = resp.data.VUE_APP_TITILE
      })
    }
    
   
    if(localStorage.getItem("token")){
      this.$store.commit('setToken', localStorage.getItem("token").replace("Bearer ", ""))
    }
  })
}
};
</script>

<style>
.bg-light {
  --bs-bg-opacity: 0;
}

body {
  background-image: url("~@/assets/cube_new.png");
  padding: 5px;
  background-position: right top;
  background-size: auto;
  background-repeat: no-repeat;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.wback {
  background-color: #fff;
  opacity: 0.75;
  color: #212529;
}

.show-font {
  color: #415a78;
}

.pointer {
  cursor: pointer;
}

.fixed-bottom {
  height: 20px;
  padding: 0px 0px 0px 10px;
  opacity: 0;
}
.fixed-bottom:hover {
  opacity: 1;
}
</style>
